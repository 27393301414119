
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITooth } from '../types';

@Component
export default class Tooth extends Vue {
  @Prop({ default: () => { } })
  readonly value: ITooth | undefined;

  @Prop({ default: () => false })
  readonly large: boolean | string | undefined;

  get toothStyle(): string {
    let stl = '';
    stl += ' width: ' + this.width + 'px;';
    stl += this.large || this.large === '' ? ' height: 200px;' : ' height: 120px;';
    return stl;
  }

  get toothClass(): string {
    let cls = '';
    cls += this.value && this.value.position === 'U' ? ' tooth-up' : ' tooth-down';
    if (this.value && this.value.gumLevel > 0) {
      cls += ' gum-lower-' + this.value.gumLevel;
    }
    return cls;
  }

  get exists(): boolean {
    return this.value !== undefined && this.value !== null && this.value.type !== 'X';
  }

  get width(): number {
    return this.large || this.large === '' ? 100 : 50;
  }

  get image(): string {
    if (this.value === undefined) {
      return '';
    } else {
      const tooth = this.value;
      let toothState = 'N';

      tooth.damages.forEach(damage => {
        if (toothState === 'N' && damage.mode !== 'N') {
          toothState = damage.mode;
        }
      });

      const type = tooth.type === 'M' ? 'R' : tooth.type;
      return '/static/teeth/' + tooth.order + tooth.position + '_' + type + toothState + tooth.roots + tooth.cure + '.svg';
    }
  }
}
